import Button from 'components/Button/Button'
import H3 from 'components/Headings/H3'
import Modal from 'components/Modal/Modal'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const OnboardedModal = ({ open, close }) => {
  const { formatMessage, locale } = useIntl()
  const { currentUser } = useCurrentUser()

  const role = currentUser?.mentor ? 'mentor' : 'mentee'

  return (
    <Modal width="sm" open={open} close={close}>
      <div className="p-10">
        <H3>
          {formatMessage({ id: 'header.youAreOnboarded' })}&nbsp;
          <span role="img" aria-label="party">
            🎉
          </span>
        </H3>
        <p className="text-lg">
          {formatMessage({
            id: `text.youAreOnboarded.${role}`,
          })}
        </p>

        {currentUser?.mentor ? (
          <Link href={`/${locale}/personal/calendar`}>
            <Button className="mt-8" variant="secondary" onClick={close}>
              {formatMessage({ id: 'button.addAvailabilities' })}
            </Button>
          </Link>
        ) : (
          <Link href={`/${locale}/mentors`}>
            <Button className="mt-8" variant="secondary" onClick={close}>
              {formatMessage({ id: 'button.browseMentors' })}
            </Button>
          </Link>
        )}
      </div>
    </Modal>
  )
}

export default OnboardedModal
