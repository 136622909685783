import { useApolloClient } from '@apollo/client'

import doLogin from './login'
import doLogout from './logout'

const useAuth = () => {
  const client = useApolloClient()

  const login = async (...args) => {
    doLogin(...args)
    await client.resetStore()
  }

  const logout = async (...args) => {
    doLogout(...args)
    await client.resetStore()
  }

  return { login, logout }
}

export default useAuth
