import classNames from 'classnames'
import { contrastBW } from 'lib/color'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Slash } from 'react-feather'

import MentorBadge from './MentorBadge'

// keep difference between sizes proportional
export const SIZES = {
  xl: 'w-24 h-24 text-lg',
  lg: 'w-20 h-20 text-lg',
  ml: 'w-16 h-16 text-lg',
  md: 'w-12 h-12 text-lg',
  sm: 'w-10 h-10 text-xs',
  s: 'w-8 h-8 text-xs',
  xs: 'w-6 h-6 text-xs',
}

const AvatarImage = ({ color, initials, imageUrl, size }) => {
  const [error, setError] = useState(false)
  const handleError = () => {
    setError(true)
  }

  if (!initials && error) {
    return (
      <div className="rounded-full flex justify-center items-center bg-red text-white h-full w-full">
        <Slash />
      </div>
    )
  }

  if (imageUrl && !error) {
    return (
      <div className="rounded-full bg-darkGray h-full w-full">
        <img
          alt={initials}
          className="rounded-full h-full w-full"
          src={imageUrl}
          onError={handleError}
        />
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'rounded-full flex justify-center items-center h-full w-full',
        {
          [`text-3xl`]: size === 'lg',
          // @TODO: add more if needed but requires testing
        },
        `text-${contrastBW(color)}`
      )}
      style={{ backgroundColor: color }}
    >
      {initials}
    </div>
  )
}

const Avatar = ({
  color,
  initials,
  imageUrl,
  mentor,
  className,
  size,
  ...props
}) => {
  const sizeClassName = SIZES[size] || SIZES.md

  // @TODO: if props undefined except for id, query based on id

  return (
    <div
      className={classNames(
        'rounded-full relative',
        sizeClassName,
        className,
        mentor ? 'shadow-yellow' : 'shadow-gray'
      )}
      {...props}
    >
      <AvatarImage
        color={color}
        initials={initials}
        imageUrl={imageUrl}
        size={size}
      />
      {mentor && (
        <MentorBadge className="absolute right-0 bottom-0 w-1/3 h-1/3" />
      )}
    </div>
  )
}

Avatar.propTypes = {
  initials: PropTypes.string,
  color: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  mentor: PropTypes.bool,
  size: PropTypes.string,
}

export const AvatarGroup = ({ children, className }) => {
  return (
    <div className={classNames('flex h-12 -space-x-4', className)}>
      {children}
    </div>
  )
}

AvatarGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Avatar.Group = AvatarGroup

export default Avatar
