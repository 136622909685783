import Logo from 'components/general/Logo'
import SmallLogo from 'components/general/SmallLogo'
import Link from 'next/link'
import { useIntl } from 'react-intl'

type MentorlyLogotypeProps = {
  group: any
  logoLink: string
}

type LogoSelectProps = {
  group: any
  logoLink: string
  size: string
}

const headerLogoLink = (url: string, locale: string) => {
  return url || `/${locale}`
}

const MentorlyLogoPill: React.FC = () => (
  <div className="relative w-16 h-16 p-4 mr-6 rounded-lg mentorlyLogoPill">
    <div className="absolute w-16 h-16 p-5 bg-yellow rounded-lg">
      <SmallLogo />
    </div>
  </div>
)

const MentorlyLogotype: React.FC<MentorlyLogotypeProps> = ({
  group,
  logoLink,
}) => {
  const { locale } = useIntl()
  return (
    <div className="w-32 lg:w-36 xl:w-40">
      <Link href={headerLogoLink(logoLink, locale)}>
        <a className="block rounded">
          <Logo
            className="fill-current text-black"
            locale={locale}
            color={group?.styles?.backgroundTextColor}
          />
        </a>
      </Link>
    </div>
  )
}

const MentorlyLogo: React.FC<LogoSelectProps> = ({ size, group, logoLink }) =>
  size === 'small' ? (
    <MentorlyLogoPill />
  ) : (
    <MentorlyLogotype group={group} logoLink={logoLink} />
  )

const GroupLogo: React.FC<LogoSelectProps> = ({ size, group, logoLink }) => {
  const { locale } = useIntl()
  const url =
    group?.[size === 'small' ? 'pageLogoImage' : 'logoImage']?.imageUrl

  if (url) {
    return (
      <Link href={headerLogoLink(logoLink, locale)}>
        <a className="cursor-pointer h-full max-w-44">
          <img
            src={url}
            alt={group.name}
            className="object-contain visible group-hover:hidden h-full"
          />
        </a>
      </Link>
    )
  } else {
    return (
      <Link href={headerLogoLink(logoLink, locale)}>
        <a>
          <h1>{group.name}</h1>
        </a>
      </Link>
    )
  }
}

const LogoSelect: React.FC<LogoSelectProps> = ({ group, size, logoLink }) => {
  return group?.whiteLabel ? (
    <GroupLogo size={size} group={group} logoLink={logoLink} />
  ) : (
    <MentorlyLogo size={size} group={group} logoLink={logoLink} />
  )
}

export default LogoSelect
