import cookie from 'js-cookie'

const removeCookies = (domain) => {
  cookie.remove('token', {
    domain: domain,
  })

  if (process.env.NEXT_PUBLIC_TOKEN_NAME) {
    cookie.remove(process.env.NEXT_PUBLIC_TOKEN_NAME, {
      domain: domain,
    })
  }

  cookie.remove('signedin', { domain: domain })
}

const logout = (domain = null) => {
  cookie.remove('token')
  cookie.remove('signedin')

  if (domain) {
    removeCookies(domain)
  }

  removeCookies(process.env.NEXT_PUBLIC_COOKIE_DOMAIN)
}

export default logout
