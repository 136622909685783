import { motion, useCycle } from 'lib/framer-motion'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { MenuToggle } from './MenuToggle'
import { Navigation } from './Navigation'

export const MobileMenu = ({ data, group }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const { asPath } = useRouter()
  const toggleMenu = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0 // For Safari
    toggleOpen()
  }

  useEffect(() => {
    isOpen && toggleOpen()
  }, [asPath])

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className={`lg:hidden flex items-center flex-col justify-center`}
    >
      <Navigation
        data={data}
        group={group}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        className="text-black"
      />
      <MenuToggle
        toggle={() => toggleMenu()}
        color={group?.styles?.backgroundTextColor}
      />
    </motion.nav>
  )
}
