import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import OnboardedModal from 'components/Personal/Onboarding/OnboardedModal'
import { contrastBW } from 'lib/color'
import { useCurrentGroup } from 'lib/GroupContext'
import { viewerOnboardingProgressQuery } from 'lib/queries/users.gql'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { User } from 'react-feather'
import { useIntl } from 'react-intl'

import ProgressRing from './ProgressRing'

const OnboardingProgress = () => {
  const { locale, formatMessage } = useIntl()
  const { data, loading } = useQuery(viewerOnboardingProgressQuery)
  const { currentGroup } = useCurrentGroup()
  const [modalOpen, setModalOpen] = useState(false)

  const [previousPercent, setPreviousPercent] = useState()
  const [currentPercent, setCurrentPercent] = useState()

  useEffect(() => {
    if (data && !previousPercent) {
      setPreviousPercent(onboardingPercent)
    }
    setCurrentPercent(onboardingPercent)
  }, [data])

  useEffect(() => {
    if (currentPercent === 100 && previousPercent < 100) {
      setModalOpen(true)
    }
  }, [currentPercent])

  if (loading || !data?.viewer || !currentGroup) return null

  const {
    profilePercent,
    onboardingPercent,
    matchingPercent,
    mentor,
  } = data.viewer
  const { manualMatching, autoMatching } = currentGroup

  const matching = autoMatching || manualMatching

  const showMatching = matching && onboardingPercent !== 100
  const showProfile = profilePercent !== 100
  const showProgress = !loading && (showMatching || showProfile)

  const percent = onboardingPercent
  const path = showProfile ? 'profile' : 'matches?preferences=true'
  const href = `/${locale}/personal/${path}`
  const groupBgColor = currentGroup.styles?.backgroundColor

  const bgOpacity =
    groupBgColor && contrastBW(groupBgColor) === 'black'
      ? 'bg-opacity-0 hover:bg-opacity-25'
      : 'bg-opacity-40 hover:bg-opacity-60'

  const message = (type, percent) => {
    const term = formatMessage({ id: `term.${type}` })
    return formatMessage({ id: 'phrase.progress' }, { term: term, percent })
  }

  return (
    <>
      <OnboardedModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        role={mentor ? 'mentor' : 'mentee'}
      />
      {showProgress && (
        <div className="hidden md:flex">
          <Link href={href}>
            <div
              className={classNames(
                'flex items-center mr-4 pr-6 py-1 bg-white rounded cursor-pointer transition duration-200 ease-in-out',
                bgOpacity
              )}
            >
              <ProgressRing icon={User} percent={percent} />
              <div className="text-xs pl-1">
                {message('profile', profilePercent)}
                <br />
                {message('matching', matchingPercent)}
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

export default OnboardingProgress
