import classNames from 'classnames'
import Feature, { getFeatureFlag } from 'components/Feature'
import LogoSelect from 'components/general/LogoSelect.tsx'
import LanguageSwitch from 'components/LanguageSwitch'
import { MobileMenu } from 'components/MobileMenu'
import OnboardingProgress from 'components/Personal/Onboarding/OnboardingProgress'
import env from 'lib/env'
import { useCurrentGroup } from 'lib/GroupContext'
import { swapFavicon } from 'lib/swapFavicon'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import LoggedOutPanel from './LoggedOutPanel'
import UserPanel from './UserPanel'

const Header = ({
  group,
  data = [],
  fullscreen,
  logoLink,
  hideMenu,
  hidePanel,
  onboardingProgress,
  previewer,
  ignoreGroup,
}) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const { currentGroup, marketplace, isDashboard } = useCurrentGroup()
  const isMentorlyGroup = !currentGroup || marketplace
  const previewGroup = group || currentGroup

  useEffect(() => {
    currentGroup?.whiteLabel && swapFavicon(currentGroup)
    window.onscroll = () => {
      isMentorlyGroup && setIsScrolling(window.scrollY < 4 ? false : true)
    }
  }, [])

  return (
    <nav
      className={classNames(
        'z-20 header flex items-center bg-backgroundColor py-4',
        isMentorlyGroup && 'sticky inset-x-0 top-0 left-0 inset-5',
        previewGroup ? 'text-backgroundTextColor' : 'font-bold',
        fullscreen ? 'px-8' : 'wrapper'
      )}
      style={{
        backgroundColor: previewer && previewGroup.styles.backgroundColor,
        color: previewer && previewGroup.styles.backgroundTextColor,
        opacity: isScrolling ? '0.9' : 1,
      }}
    >
      <div
        className={classNames(
          'w-full h-full block flex-grow flex items-center justify-between lg:w-auto',
          !fullscreen && 'container mx-auto'
        )}
      >
        <LogoSelect
          group={!ignoreGroup && previewGroup}
          size="large"
          logoLink={logoLink}
        />

        <div className="text-md hidden ml-9 lg:flex lg:ml-12 items-center flex-grow">
          {!hideMenu &&
            data?.map((item, i) => (
              <NavEl key={i} group={previewGroup} {...item} />
            ))}
        </div>

        {!isDashboard && onboardingProgress && (
          <OnboardingProgress data={onboardingProgress} />
        )}

        <div className="hidden flex-shrink-0 lg:flex self-end items-center text-right my-auto">
          {!hidePanel && <Panel group={previewGroup} />}

          <div>
            <Feature id="languageToggle">
              <LanguageSwitch
                className="rounded font-black uppercase ml-2"
                short
              />
            </Feature>
          </div>
        </div>
      </div>
      {!hideMenu && (
        <MobileMenu group={!ignoreGroup && previewGroup} data={data || []} />
      )}
    </nav>
  )
}

export const Panel = ({ group, toggleOpen, inline }) => {
  const { currentUser } = useCurrentUser()
  return currentUser ? (
    <UserPanel
      group={group}
      user={currentUser}
      inline={inline}
      toggleOpen={toggleOpen}
    />
  ) : (
    <LoggedOutPanel group={group} toggleOpen={toggleOpen} />
  )
}

const Nav = ({ children, className }) => (
  <nav>
    <ul className={classNames('flex', className)}>{children}</ul>
  </nav>
)

const NavEl = ({ className, group, id, legacy, needAuth, path, test }) => {
  const { currentUser } = useCurrentUser()
  const { formatMessage, locale } = useIntl()

  if (!getFeatureFlag(group, id)) {
    return null
  }

  if (test && env.production) {
    return null
  }

  if (needAuth && !currentUser) {
    return null
  }

  if (legacy) {
    return (
      <a
        href={path}
        className={classNames('mr-5 xl:mr-10 rounded', className)}
        target={id === 'menu.faq' ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {formatMessage({ id })}
      </a>
    )
  }

  return (
    <Link href={`/${locale}${path}`}>
      <a className={classNames('mr-5 xl:mr-10 rounded', className)}>
        {formatMessage({ id })}
      </a>
    </Link>
  )
}

Header.Nav = Nav
Header.NavEl = NavEl
export default Header
