import classNames from 'classnames'
import { ButtonLink } from 'components/Button'
import { getFeatureFlag } from 'components/Feature'
import { useCurrentGroup } from 'lib/GroupContext'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const SignUp = ({ children }) => {
  const { formatMessage, locale } = useIntl()
  const { currentGroup } = useCurrentGroup()
  if (!currentGroup) return null // must be a group to sign-up to
  const url = `/${locale}/sign-up`
  const label = formatMessage({ id: 'button.signUp' })

  return getFeatureFlag(currentGroup, 'signUp') ? children(url, label) : null
}

const SignUpLink = () => {
  return <SignUp>{(url, label) => <Link href={url}>{label}</Link>}</SignUp>
}

const SignUpButton = ({ onClick, className }) => {
  return (
    <SignUp>
      {(url, label) => (
        <div
          className={classNames('mr-1 inline-block', className)}
          onClick={onClick}
        >
          <Link href={url} passHref>
            <ButtonLink variant="primary">{label}</ButtonLink>
          </Link>
        </div>
      )}
    </SignUp>
  )
}

export { SignUpLink, SignUpButton }
