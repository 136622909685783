import cookie from 'js-cookie'
import { useRouter } from 'next/router'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

const locales = ['en', 'fr']
const regex = new RegExp(`^/(${locales.join('|')})`)

const LanguageSwitch = ({ className, onClick, short }) => {
  const router = useRouter()
  const intl = useIntl()
  const alternateLocale = intl.locale === 'en' ? 'fr' : 'en'

  const newPath = router.asPath.replace(regex, `/${alternateLocale}`)

  const text = short
    ? alternateLocale
    : intl.formatMessage({ id: 'alternateLocale' })

  const onLanguageClick = () => {
    cookie.set('locale', alternateLocale, { expires: 365 })
    router.push(router.pathname, newPath)

    if (onClick) {
      onClick()
    }
  }

  return (
    <button className={className} onClick={onLanguageClick}>
      {text}
      <Helmet htmlAttributes={{ lang: intl.locale }} />
    </button>
  )
}

export default LanguageSwitch
