import classNames from 'classnames'
import { useState } from 'react'

const Dropdown = ({ className, trigger, children }) => {
  const [state, setState] = useState(false)

  if (!children) return false

  const toggle = () => {
    setState(!state)
  }

  const open = () => {
    setState(true)
  }

  const close = () => {
    setState(false)
  }

  const params = {
    setState,
    open,
    close,
    toggle,
  }

  return (
    <div className={classNames('relative', className)}>
      {trigger(params)}
      {state && children(params)}
    </div>
  )
}

export default Dropdown
