import { ButtonLink } from 'components/Button'
import { SignUpButton } from 'components/Forms/SignUp/SignUpLink'
import Link from 'next/link'
import React from 'react'
import { useIntl } from 'react-intl'

const LoggedOutPanel = ({ group, toggleOpen }) => {
  const { formatMessage, locale } = useIntl()
  const color = group?.styles.backgroundTextColor

  return (
    <>
      <div className="mr-1 inline-block" onClick={toggleOpen}>
        <Link href={`/${locale}/login`} passHref>
          <ButtonLink
            variant="secondary"
            style={group && { border: 'none', color: color }}
          >
            {formatMessage({ id: 'button.signIn' })}
          </ButtonLink>
        </Link>
      </div>
      <SignUpButton onClick={toggleOpen} />
    </>
  )
}

export default LoggedOutPanel
