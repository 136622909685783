import cookie from 'js-cookie'

const setCookies = (token, domain) => {
  const cookieName = process.env.NEXT_PUBLIC_TOKEN_NAME || 'token'

  cookie.set(cookieName, token, {
    domain: domain,
    expires: 14,
  })

  cookie.set('signedin', true, {
    domain: domain,
    expires: 14,
  })
}

const login = (token, domain = null) => {
  if (domain) {
    setCookies(token, domain)
  }

  setCookies(token, process.env.NEXT_PUBLIC_COOKIE_DOMAIN)
}

export default login
