/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames'
import { motion } from 'lib/framer-motion'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export const MenuLink = ({ item, toggleOpen }) => {
  const { locale, formatMessage } = useIntl()

  if (item.legacy) {
    return (
      <a
        href={item.path}
        className="text-2xl"
        onClick={toggleOpen}
        onKeyPress={toggleOpen}
      >
        {formatMessage({ id: item.id })}
      </a>
    )
  }

  return (
    <Link href={`/${locale}${item.path}`}>
      <a className="text-2xl" onClick={toggleOpen} onKeyPress={toggleOpen}>
        {formatMessage({ id: item.id })}
      </a>
    </Link>
  )
}

export const MenuItem = ({ children, animateOnly, className }) => {
  return (
    <motion.li variants={variants} className={classNames('mb-1', className)}>
      <motion.div
        whileHover={!animateOnly && { x: -5 }}
        whileTap={!animateOnly && { x: 5 }}
        className="font-black text-left py-2"
      >
        {children}
      </motion.div>
    </motion.li>
  )
}
